import React from 'react';
import { Swipeable } from 'react-swipeable';
import gravewalkersRed from '../images/gravewalkers-red.png';
import gravewalkersBlue from '../images/gravewalkers-blue.png';

let CalendarView = (props) => {
  let calendarRange = () => {
    let ary = Array(28).fill().map((_, idx) => {
      let val = 1 + idx;
      let dayStyles = {
        borderRadius: 100,
        marginBottom: '0.3vw',
        display: 'inline-block',
        backgroundColor: 'white',
        width: '14.28%',
        boxSizing: 'border-box',
        cursor: 'pointer',
        position: 'relative'
      };

      let borderColor = val === props.day ? '#f23838' : 'white';
      dayStyles = Object.assign({}, dayStyles, { border: `solid 0.35vw ${borderColor}`});

      let GroupScheduled = () => {
        if(
          props.root.state.groupSchedule &&
          props.root.state.groupSchedule.hasOwnProperty(val+'') &&
          props.root.state.groupSchedule[val+''] !== ''
        ) {
          return (
            <span style={{ position: 'absolute', top: 0, bottom: 0, right: 3, display: 'flex', alignItems: 'center'}} >
              <img alt="group-item" style={{ width: '2vw', height: 'auto', display: 'block' }} src={gravewalkersRed} />
            </span>
          )
        } else { return null }
      }

      let UserScheduled = () => {
        if(
          props.root.state.userSchedule &&
          props.root.state.userSchedule.hasOwnProperty(val+'') &&
          props.root.state.userSchedule[val+''] !== ''
        ) {
          return (
            <span style={{ position: 'absolute', top: 0, bottom: 0, left: 3, display: 'flex', alignItems: 'center'}} >
              <img alt="user-item" style={{ width: '2vw', height: 'auto', display: 'block' }} src={gravewalkersBlue} />
            </span>
          )
        } else { return null }
      }

      return(
        <div key={idx} style={dayStyles} onClick={() => { props.updateViewDay(val) }}>
          <UserScheduled />
          <GroupScheduled />
          {val}
        </div>
      )
    });
    return(
      <div>
        {ary}
      </div>
    )
  }

  let dayRange = () => {
    let ary = Array(7).fill().map((_, idx) => {
      let val = 1 + idx;
      let dayStyles = {
        borderRadius: 100,
        marginBottom: '0.3vw',
        display: 'inline-block',
        backgroundColor: 'white',
        width: '14.28%',
        boxSizing: 'border-box',
      };

      let borderColor = 'white';
      dayStyles = Object.assign({}, dayStyles, { border: `solid 0.35vw ${borderColor}`});
      return(
        <div key={idx} style={dayStyles}>
          {props.root.weekday(val)}
        </div>
      )
    });
    return(
      <div>
        {ary}
      </div>
    )
  }

  let swipeHandler = (evt) => {
    switch(evt.dir) {
      case 'Up':
      case 'Left':
        props.root.advanceViewMonth();
        break;
      case 'Down':
      case 'Right':
        props.root.reduceViewMonth();
        break;
      default:
        break;
    }
  }

  let swipeableConfig = {
    delta: 10,                             // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false,   // preventDefault on touchmove, *See Details*
    trackTouch: true,                      // track touch input
    trackMouse: true,                     // track mouse input
    rotationAngle: 0                       // set a rotation angle
  }

  return (
    <section className="CalendarView">
      <Swipeable onSwiped={(eventData) => { swipeHandler(eventData) }} {...swipeableConfig}>
        <div style={{ width: '90vw', display: 'block' }} className="noselect">
          <div className="CalendarMonthYear group">
            <span className="month" style={{ color: props.root.monthColor(props.month) }}>{props.month.charAt(0).toUpperCase() + props.month.slice(1)}</span>
            <span className="year">{props.year}</span>
            <span className="today" onClick={props.root.setViewDateToCurrentDate}>Today</span>
          </div>
          <div className="weekday">
            {dayRange()}
          </div>
          <div style={{ display: 'block', height: '100%', fontSize: 'calc(6vw)' }}>
            {calendarRange()}
          </div>
        </div>
      </Swipeable>
      <div className="UpdateCurrentDate noselect" onClick={props.root.updateCurrentDate}>
        Update Current Date
      </div>
    </section>
  );
}

export default CalendarView;

import React from 'react';
import { Swipeable } from 'react-swipeable';

let CurrentDayView = (props) => {

  let swipeableConfig = {
    delta: 10,                             // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false,   // preventDefault on touchmove, *See Details*
    trackTouch: true,                      // track touch input
    trackMouse: true,                     // track mouse input
    rotationAngle: 0                       // set a rotation angle
  }

  let swipeHandler = (evt) => {
    switch(evt.dir) {
      case 'Up':
      case 'Left':
        props.root.advanceDimensionDays();
        break;
      case 'Down':
      case 'Right':
        props.root.reduceDimensionDays();
        break;
      default:
        break;
    }
  }

  return (
    <section className="CurrentDayView noselect">
      <Swipeable onSwiped={(eventData) => { swipeHandler(eventData) }} {...swipeableConfig}>
        <div style={{ display: 'block', height: '100%' }}>
        <hr />
        <div className="DateHolder">
          <span className="month" style={{ color: props.root.monthColor(props.month) }}> {props.month.charAt(0).toUpperCase() + props.month.slice(1)}</span>
          <span className="day">{props.root.cardinalizeDay(props.day)}</span>
          <span className="weekday"> ({props.root.weekday(props.day)})</span>
          <span className="year">{props.root.state.year}</span>
        </div>
        <hr />
        <div className="InterdimensionCounterHolder group">
          Days in Dimension <span className="year">{props.root.state.dimensionDays}</span>
          <span className="ResetDimentionDays" onClick={props.root.resetDimensionDays}>RESET</span>
        </div>
        <hr />
      </div>
      </Swipeable>
    </section>
  );
}

export default CurrentDayView;

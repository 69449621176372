import React from 'react';

let currentDate = (props) => {
  if(props)
  return (
    <span>
      <span className="month"> {props.root.state.viewMonth.charAt(0).toUpperCase() + props.root.state.viewMonth.slice(1)}</span>
      <span className="day"> {props.root.cardinalizeDay(props.root.state.viewDay)}</span>
      <span className="weekday"> ({props.root.weekday(props.root.state.viewDay)})</span>
      <span className="year"> {props.root.state.viewYear}</span>
    </span>
  )
}

let groupScheduleText = (props) => {
  // console.log('$$$$$$$$$$$$$$$$$$$$$ >>>');
  // console.log(props.root.state.viewDay);
  // console.log(props.root.state.groupSchedule[props.root.state.viewDay+'']);
  // console.log('$$$$$$$$$$$$$$$$$$$$$ <<<');

  if(props.root.state.groupSchedule && props.root.state.groupSchedule.hasOwnProperty(props.root.state.viewDay+'')) {
    return (props.root.state.groupSchedule[props.root.state.viewDay+''])
  } else { return '' }
}

let userScheduleText = (props) => {
  if(props.root.state.userSchedule && props.root.state.userSchedule.hasOwnProperty(props.root.state.viewDay+'')) {
    return (props.root.state.userSchedule[props.root.state.viewDay+''])
  } else { return '' }
}

let updateScheduleTextState = (props, text, schedule) => {
  let updatedState = {}
  updatedState[props.root.state.viewDay] = text;

  let newState = {}
  newState[schedule] = Object.assign({}, props.root.state[schedule], updatedState)
  props.root.setState(newState);
}

let ScheduleView = (props) => {
  return (
    <div style={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      <div className="bluemode">
        <div className="ScheduleView">
          <div className="JournalLabel">
            <span className="JournalType">USER JOURNAL</span><span className="JournalDate">{currentDate(props)}</span>
          </div>
          <textarea className="GroupSchedule ScheduleInput" value={userScheduleText(props)} onChange={(e)=>{ updateScheduleTextState(props, e.target.value, 'userSchedule') }}></textarea>
          <div className="UpdateJournal noselect" onClick={()=>{ props.root.updateFireStoreUserSchedule() }}>
            Update USER Journal
          </div>
        </div>
      </div>

      <div className="ScheduleView redmode" style={{ marginTop: '4vw' }}>
        <div className="JournalLabel">
          <span className="JournalType">GROUP JOURNAL</span><span className="JournalDate">{currentDate(props)}</span>
        </div>
        <textarea className="GroupSchedule ScheduleInput" value={groupScheduleText(props)} onChange={(e)=>{ updateScheduleTextState(props, e.target.value, 'groupSchedule') }}></textarea>
        <div className="UpdateJournal noselect" onClick={()=>{ props.root.updateFireStoreGroupSchedule() }}>
          Update Group Journal
        </div>
      </div>
    </div>
  );
}

export default ScheduleView;

// import firebase from 'firebase/database';
const firebase = require('firebase/app');
require('firebase/firestore');
require('firebase/auth');

var firebaseConfig = {
  apiKey: "AIzaSyAf-o62-KSE6VwMZDtbR5ia4NJtBLb3gU4",
  authDomain: "calendar-e0db8.firebaseapp.com",
  databaseURL: "https://calendar-e0db8.firebaseio.com",
  projectId: "calendar-e0db8",
  storageBucket: "calendar-e0db8.appspot.com",
  messagingSenderId: "1062868325351",
  appId: "1:1062868325351:web:b5578b36dd3ec8afa3a342",
  measurementId: "G-S3SHHDG1GP"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;

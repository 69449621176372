import React from 'react';
import './App.css';
import firebase from "./components/Firestore";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Calendar from './routes/Calendar';
import Bank from './routes/Bank';

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false
  },
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ]
};
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isSignedIn: false,
      user: {},
    };
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        this.setState({isSignedIn: !!user, user: user})
      }
    );
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  AppViewModel = () => {
    if(!this.state.isSignedIn || !this.state.user) {
      return (
        <div>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
        </div>
      );
    }
    else {
      return (
        <Router basename="">
          <div className="row">
            <Route exact path="/" render={() => <Calendar user={this.state.user} isSignedIn={this.state.isSignedIn} />} />
            <Route path="/bank" render={() => <Bank googleUser={this.state.user} googleIsSignedIn={this.state.isSignedIn} />} />
          </div>
        </Router>
      );
    }
  }

  render() { return( this.AppViewModel() )}
}

export default App;

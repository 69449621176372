import React from 'react';
import '../App.css';
import CurrentDayView from '../components/CurrentDayView';
import CalendarView from '../components/CalendarView';
import ScheduleView from '../components/ScheduleView';

import firebase from "../components/Firestore";

const db = firebase.firestore();

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      day: 0,
      month: '',
      year: 0,
      dimensionDays: -1,
      isSignedIn: props.isSignedIn,
      user: props.user,
      userSchedule: {},
      groupSchedule: {},
      box: {},
      bank: {},
    };

    this.state.viewDay = this.state.day;
    this.state.viewMonth = this.state.month;
    this.state.viewYear = this.state.year;
  }

  updateViewDay = (day) => { this.setState({ viewDay: day }) }
  updateViewMonth = (date) => { this.setState({ viewMonth: date }) }

  updateFireStoreDate = (data) => {
    db.collection("date").doc("current").update(data);
  }

  updateFireStoreDimension = (dimensionDays) => {
    db.collection("date").doc("dimension").update({ days: dimensionDays });
  }

  updateFireStoreUserSchedule = () => {
    if(window.confirm("Update User Journal?")) {
      db.collection("schedule").doc(this.state.viewYear+'').collection(this.state.viewMonth+'').doc(this.state.user.uid+'').set(this.state.userSchedule);
    }
  }

  updateFireStoreGroupSchedule = () => {
    if(window.confirm("Update Group Journal? This change will be visible to everyone!")) {
      db.collection("schedule").doc(this.state.viewYear+'').collection(this.state.viewMonth+'').doc('group').set(this.state.groupSchedule);
    }
  }

  retreiveCalendarSchedules = (year, month) => {
    const appRef = this;
    db.collection('schedule').doc(year+'').collection(month+'').doc('group').onSnapshot(function(doc) {
      let groupData = doc.data();
      appRef.setState({ groupSchedule: groupData });
    });

    if(appRef.state.user.hasOwnProperty('uid')) {
      db.collection('schedule').doc(year+'').collection(month+'').doc(appRef.state.user.uid+'').onSnapshot(function(doc) {
        let userData = doc.data();
        appRef.setState({ userSchedule: userData })
      });
    }
  }

  retreiveFireStoreDate = () => {
    const appRef = this;
    db.collection("date").doc("current").onSnapshot(function(doc) {
      let data = doc.data();
      appRef.retreiveCalendarSchedules(data.year, data.month);
      appRef.setState({ day: data.day, month: data.month, year: data.year });
      if(appRef.state.viewDay === 0 || appRef.state.viewMonth === '' || appRef.state.year === 0) {
        appRef.setState({ viewDay: data.day, viewMonth: data.month, viewYear: data.year })
      }
    });
    db.collection("date").doc("dimension").onSnapshot(function(doc) {
      let data = doc.data();
      appRef.setState({ dimensionDays: data.days })
    });
  }

  suffixDictionary = (num) => {
    let suffix = 'th';
    let str = num.toString();
    let precursor = str.length > 1 ? parseInt(str.split('').slice(-2)[0]) : 0;

    switch(parseInt(str.split('').slice(-1)[0])) {
      case 1:
        suffix = precursor === 1 ? 'th' : 'st'
        break;
      case 2:
        suffix = precursor === 1 ? 'th' : 'nd'
        break;
      case 3:
        suffix = precursor === 1 ? 'th' : 'rd'
        break;
      default:
        suffix = 'th'
    }
    return num+suffix
  }

  cardinalizeDay = (date) => { return this.suffixDictionary(date) }

  monthColor = (month) => {
    let color = 'black';
    switch(month) {
      case 'zarantyr':
      case 'olarune':
      case 'vult':
        color = '#104b94';
        break
      case 'therendor':
      case 'eyre':
      case 'dravago':
        color = '#20b358';
        break
      case 'nymm':
      case 'lharvion':
      case 'barrakas':
        color = '#d4b702';
        break
      case 'rhaan':
      case 'sypheros':
      case 'aryth':
        color = '#a6640d';
        break
      default:
        break;
    }

    return color
  }

  monthList = [
    'zarantyr',
    'olarune',
    'therendor',
    'eyre',
    'dravago',
    'nymm',
    'lharvion',
    'barrakas',
    'rhaan',
    'sypheros',
    'aryth',
    'vult'
  ]

  dayList = [
    'Sar',
    'Sul',
    'Mol',
    'Zol',
    'Wir',
    'Zor',
    'Far',
  ]

  weekday = (day) => {
    return this.dayList[day%7];
  }

  /* ------------------------------------------------------------ */
  /* DIMENSION METHODS */
  /* ------------------------------------------------------------ */
  advanceDimensionDays = () => {
    let dd = this.state.dimensionDays;
    this.updateFireStoreDimension(dd + 1);
  }

  reduceDimensionDays = () => {
    let dd = this.state.dimensionDays;
    if(dd - 1 >= 0) { this.updateFireStoreDimension(dd - 1); }
  }

  resetDimensionDays = () => {
    if(window.confirm("Set Dimension Days to 0?")) { this.updateFireStoreDimension(0) }
  }

  /* ------------------------------------------------------------ */
  /* DATE METHODS */
  /* ------------------------------------------------------------ */
  getCurrentDate = () => {
    let data = {
      month: this.state.month,
      year: this.state.year,
      day: this.state.day,
      weekday: this.weekday(this.state.day),
      monthDisplay: this.state.month.charAt(0).toUpperCase() + this.state.month.slice(1),
      dayDisplay: this.cardinalizeDay(this.state.day),
      full: this.state.year + '-' + this.state.month + '-' + this.state.day,
    }
    data['fullDisplay'] = data['weekday'] + ', ' + data['monthDisplay'] + ' ' + data['dayDisplay'] + ', ' + data['year']
    return data
  }

  getCurrentViewDate = () => {
    let data = {
      month: this.state.viewMonth,
      year: this.state.viewYear,
      day: this.state.viewDay,
      weekday: this.weekday(this.state.viewDay),
      monthDisplay: this.state.viewMonth.charAt(0).toUpperCase() + this.state.viewMonth.slice(1),
      dayDisplay: this.cardinalizeDay(this.state.viewDay),
      full: this.state.viewYear + '-' + this.state.viewMonth + '-' + this.state.viewDay,
    }
    data['fullDisplay'] = data['weekday'] + ', ' + data['monthDisplay'] + ' ' + data['dayDisplay'] + ', ' + data['year']
    return data
  }

  updateCurrentDate = () => {
    let cd = this.getCurrentDate().fullDisplay;
    let cvd = this.getCurrentViewDate().fullDisplay;

    if(cd !== cvd && window.confirm("Set current date to " + cvd + "?")) {
      this.updateFireStoreDate({ day: this.state.viewDay, month: this.state.viewMonth, year: this.state.viewYear })
    }
  }

  setViewDateToCurrentDate = () => {
    let cd = this.getCurrentDate().fullDisplay;
    let cvd = this.getCurrentViewDate().fullDisplay;

    if(cd !== cvd) {
      this.setState({ viewDay: this.state.day, viewMonth: this.state.month, viewYear: this.state.year })
    }
  }

  /* ------------------------------------------------------------ */
  /* YEAR METHODS */
  /* ------------------------------------------------------------ */
  reduceViewYearBy = (num) => { this.setState({ viewYear: this.state.viewYear - num }); }
  advanceViewYearBy = (num) => { this.setState({ viewYear: this.state.viewYear + num }); }

  /* ------------------------------------------------------------ */
  /* MONTH METHODS */
  /* ------------------------------------------------------------ */

  nextMonth = (month = null) => {
    let idx = this.monthList.indexOf(month);
    let advanceYear = idx + 1 > this.monthList.length - 1;
    return { month: advanceYear ? this.monthList[0] : this.monthList[idx+1], advanceYear: advanceYear };
  }

  advanceMonth = (update = true) => {
    let nextMonthData = this.nextMonth(this.state.month);
    let data = { month: nextMonthData.month }

    if(nextMonthData.advanceYear) { data.year = this.state.year + 1; }
    if(update) { this.updateFireStoreDate(data) }
    else { return data }
  }

  advanceViewMonth = () => {
    let nextMonthData = this.nextMonth(this.state.viewMonth);
    if(nextMonthData.advanceYear) { this.advanceViewYearBy(1); }
    this.updateViewMonth(nextMonthData.month);
  }

  prevMonth = (month = null) => {
    let idx = this.monthList.indexOf(month);
    let reduceYear = idx - 1 < 0;
    return { month: reduceYear ? this.monthList[this.monthList.length - 1] : this.monthList[idx-1], reduceYear: reduceYear };
  }

  reduceMonth = (update = true) => {
    let prevMonthData = this.prevMonth(this.state.month);
    let data = { month: prevMonthData.month }

    if(prevMonthData.reduceYear) { data.year = this.state.year - 1; }
    if(update) { this.updateFireStoreDate(data) }
    else { return data }
  }

  reduceViewMonth = () => {
    let prevMonthData = this.prevMonth(this.state.viewMonth);
    if(prevMonthData.reduceYear) { this.reduceViewYearBy(1); }
    this.updateViewMonth(prevMonthData.month);
  }


  /* ------------------------------------------------------------ */
  /* DAY METHODS */
  /* ------------------------------------------------------------ */

  reduceDay = () => {
    let reduction = this.state.day - 1;
    let newDate = reduction;
    let data = {};
    if (reduction === 0) {
      newDate = 28;
      data = this.reduceMonth(false);
    }

    data.day = newDate;
    this.updateFireStoreDate(data);
  }

  advanceDay = () => {
    let advancement = this.state.day + 1;
    let newDate = advancement;
    let data = {};
    if (advancement === 29) {
      newDate = 1;
      data = this.advanceMonth(false);
    }

    data.day = newDate;
    this.updateFireStoreDate(data);
  }

  AppViewModel = () => {
    if(this.state.day === 0) {
      return (
        <div className="App">
          <div style={{ fontSize: '6vw' }}>Loading...</div>
        </div>
      )
    }
    else {
      return (
        <div className="App">
          <CurrentDayView root={this} day={this.state.day} month={this.state.month} year={this.state.year} />
          <CalendarView root={this} day={this.state.viewDay} month={this.state.viewMonth} year={this.state.viewYear} updateViewDay={this.updateViewDay}/>
          <ScheduleView root={this}  />
          <div className="UpdateBank noselect" style={{ marginBottom: 50 }} onClick={()=>{ window.location = '/bank' }}>
            Bank
          </div>
        </div>
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // update our schedule if our view month/year change
    if (prevState.viewYear !== this.state.viewYear || prevState.viewMonth !== this.state.viewMonth) {
      this.retreiveCalendarSchedules(this.state.viewYear, this.state.viewMonth);
    }
  }

  componentDidMount() {
    if(this.state.day === 0) { this.retreiveFireStoreDate(); }
  }

  render() {
    return(
      this.AppViewModel()
    )
  }
}

export default Calendar;

import React from 'react';
import { create, all } from 'mathjs'

const config = { };
const math = create(all, config)

window.math = math;

let setStorageSpaceVal = (inputVal, type, v) => {
  let temp = {};
  temp[type] = v;

  inputVal.set(Object.assign({}, inputVal.value, temp ))
}

let bankEval = (target, inputVal, update = true) => {
  let currentVal = target.getAttribute('amount');
  let type = target.getAttribute('cointype');

  let exp = target.value;
  let newVal = currentVal;

  if(exp && exp !== '' && typeof exp !== "undefined" && ['+','-','/','*'].includes((''+exp).replaceAll(' ','').charAt(0))) {
    try { newVal = Math.floor(math.evaluate(''+currentVal+exp)); }
    catch { newVal = Math.floor(math.evaluate(exp)); }
  } else {
    try { newVal = Math.floor(math.evaluate(''+exp)); }
    catch { newVal = Math.floor(math.evaluate(exp)); }
  }

  try {
    if(!isNaN(newVal) && newVal < 0 && update) {
      throw new Error('Are we really that broke? How did we end up with ' + newVal + ' ' + type + '?');
    }

    if(update) { setStorageSpaceVal(inputVal, type, newVal); }
    else { return newVal }
  }
  catch (err) { alert(err) }
}

let updateContainer = (container, inputVal, storage, updateFireStoreStorage) => {
  let temp = {};

  try {
    Array.from(document.getElementsByClassName(container+"InputField")).forEach((field) => {
      let cointype = field.getAttribute('cointype');
      let amount = field.getAttribute('amount');
      let newVal = bankEval(field, inputVal, false);
      if(!isNaN(newVal) && newVal < 0) {
        throw new Error('Are we really that broke? How did we end up with ' + newVal + ' ' + cointype + '?');
      } else {
        temp[cointype] = (newVal === undefined || isNaN(newVal)) ? amount : newVal;
      }
    })
  } catch(err) { alert(err); }

  if(
    window.confirm(
      "Update " + container.charAt(0).toUpperCase() + container.slice(1) + ' to? \n' +
      "PP: " + temp.PP + '\n' +
      "GP: " + temp.GP + '\n' +
      "EP: " + temp.EP + '\n' +
      "SP: " + temp.SP + '\n' +
      "BP: " + temp.BP + '\n'
    )
  ) {
    inputVal.set({ PP: '', GP: '', EP: '', SP: '', BP: '' });
    temp.misc = typeof storage.value.misc === "undefined" ? '' : storage.value.misc;

    updateFireStoreStorage(container, temp);
  }
}

let BankRow = ({ amount, type, inputVal, container }) => {
  return (
    <div className={"bankRow"}>
      <span className="bankValue">
        <span>{amount}</span>
        <span>{type}</span>
      </span>
      <span className={"bankInput"}>
        <input style={{ width: '80%' }} inputMode="tel" cointype={type} amount={amount} className={container+"InputField-" + type + " "+container+"InputField"} type="text" value={inputVal.value[type] || ''} onChange={(v)=>{ setStorageSpaceVal(inputVal, type, v.currentTarget.value)}}></input>
      </span>
      <span className="bankAction">
        <span className="button yellow" onClick={(e) => { bankEval(document.getElementsByClassName(container+'InputField-'+type)[0], inputVal) }}>=</span>
      </span>
    </div>
  )
}

let Inventory = ({ storage, inputVal, label, container, updateFireStoreStorage }) => {
  return (
    <div className={"BankView "+ container}>
      <hr />
        <span style={{ textTransform: 'uppercase', fontWeight: '700' }}>{label}</span>
      <hr />
      <BankRow amount={storage.value.PP || 0} inputVal={inputVal} type="PP" container={container} />
      <BankRow amount={storage.value.GP || 0} inputVal={inputVal} type="GP" container={container} />
      <BankRow amount={storage.value.EP || 0} inputVal={inputVal} type="EP" container={container} />
      <BankRow amount={storage.value.SP || 0} inputVal={inputVal} type="SP" container={container} />
      <BankRow amount={storage.value.BP || 0} inputVal={inputVal} type="BP" container={container} />
      <div className="bankMisc">
        <div>MISC</div>
        <textarea className="BankInput" value={storage.value.misc} onChange={(v)=>{ setStorageSpaceVal(storage, 'misc', v.currentTarget.value)}}></textarea>
      </div>

      <div className="UpdateBank noselect" onClick={()=>{ updateContainer(container, inputVal, storage, updateFireStoreStorage) }}>
        Update {label}
      </div>
    </div>
  )
}

let BankView = ({state, updateFireStoreStorage}) => {
  return (
    <div style={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      <Inventory storage={ state.personal } inputVal={ state.inputPersonal } label='Personal' container='personal' updateFireStoreStorage={updateFireStoreStorage} />
      <Inventory storage={ state.box } inputVal={ state.inputBox } label='Box' container='box' updateFireStoreStorage={updateFireStoreStorage} />
      <Inventory storage={ state.bank } inputVal={ state.inputBank } label='Bank' container='bank' updateFireStoreStorage={updateFireStoreStorage} />
      <Inventory storage={ state.heimat } inputVal={ state.inputHeimat } label='Heimat' container='heimat' updateFireStoreStorage={updateFireStoreStorage} />
      <Inventory storage={ state.apartment } inputVal={ state.inputApartment } label='Apartment' container='apartment' updateFireStoreStorage={updateFireStoreStorage} />
      <Inventory storage={ state.elkoris } inputVal={ state.inputElkoris } label='Elkoris Estate' container='elkoris' updateFireStoreStorage={updateFireStoreStorage} />
      <Inventory storage={ state.tybalt } inputVal={ state.inputTybalt } label='Tybalt' container='tybalt' updateFireStoreStorage={updateFireStoreStorage} />
      <Inventory storage={ state.castle } inputVal={ state.inputCastle } label='Castle' container='castle' updateFireStoreStorage={updateFireStoreStorage} />
    </div>
  );
}

export default BankView;

import React, { useEffect, useCallback, useState } from 'react';
import '../App.css';
import BankView from '../components/BankView';

import firebase from "../components/Firestore";

const db = firebase.firestore();

let Bank = ({ googleUser, googleIsSignedIn }) => {
  let reduction = (result, item, index) => {
    let key = index === 0 ? 'value' : 'set';
    result[key] = item;
    return result
  }

  let state = {
    signedIn: useState(googleIsSignedIn).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    user: useState(googleUser).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    bank: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    box: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    heimat: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    apartment: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    elkoris: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    castle: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    tybalt: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    personal: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    inputBox: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    inputBank: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    inputPersonal: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    inputHeimat: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    inputApartment: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    inputTybalt: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    inputElkoris: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    inputCastle: useState({}).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
    fetched: useState(false).reduce((result, item, index) => { return reduction(result, item, index) }, {}),
  }

  const retreiveBankValues = useCallback(() => {
    db.collection('storage').doc('box').onSnapshot((doc) => {
      let boxData = doc.data();

      state.box.set(boxData);
      state.fetched.set(true);
    });

    db.collection('storage').doc('bank').onSnapshot((doc) => {
      let bankData = doc.data();
      state.bank.set(bankData);
    });

    db.collection('storage').doc('heimat').onSnapshot((doc) => {
      let heimatData = doc.data();
      state.heimat.set(heimatData);
    });

    db.collection('storage').doc('apartment').onSnapshot((doc) => {
      let apartmentData = doc.data();
      state.apartment.set(apartmentData);
    });

    db.collection('storage').doc('tybalt').onSnapshot((doc) => {
      let tybaltData = doc.data() || {};
      state.tybalt.set(tybaltData);
    });

    db.collection('storage').doc('elkoris').onSnapshot((doc) => {
      let elkorisData = doc.data();
      state.elkoris.set(elkorisData);
    });

    db.collection('storage').doc('castle').onSnapshot((doc) => {
      let castleData = doc.data();
      state.castle.set(castleData);
    });

    db.collection("storage").doc('personal').collection(googleUser.uid+'').doc('carried').onSnapshot((doc) => {
      let personalData = doc.data();
      state.personal.set(personalData);
    });
  }, [state,googleUser])

  const retreiveFireStoreDate = useCallback(() => {
    retreiveBankValues();
  }, [retreiveBankValues])

  let updateFireStoreStorage = (doc, vals) => {
    if(doc === 'personal') {
      db.collection("storage").doc('personal').collection(googleUser.uid+'').doc('carried').set(vals);
    } else {
      db.collection("storage").doc(doc).set(vals);
    }
  }

  useEffect(()=>{
    if(!state.fetched.value) { retreiveFireStoreDate(); }
  }, [state, retreiveFireStoreDate])

  let AppViewModel = () => {
    if(!state.fetched.value) {
      return (
        <div className="App">
          <div style={{ fontSize: '6vw' }}>Loading...</div>
        </div>
      )
    }
    else {
      return (
        <div className="App">
          <div className="UpdateBank noselect" onClick={()=>{ window.location = '/' }}>
            Calendar
          </div>
          <BankView state={state} retreiveBankValues={retreiveBankValues} updateFireStoreStorage={updateFireStoreStorage} />
        </div>
      );
    }
  }

  return(
    AppViewModel()
  )
}

export default Bank;
